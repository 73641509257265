import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { initGA, logPageView } from './utils/analytics';
import Auth from './components/Auth/Auth';
import Lobby from './components/Lobby/Lobby';
import Table from './components/Table/Table';
import JoinGame from './components/JoinGame/JoinGame';
import HostView from './components/HostView/HostView';
import HostTableView from './components/HostView/HostTableView';
import Landing from './components/Landing/Landing';
import PokerTable from './components/PokerTable';
import CommunitiesPage from './pages/CommunitiesPage';
import CreateCommunityPage from './pages/CreateCommunityPage';
import CommunityDetailsPage from './pages/CommunityDetailsPage';
import ProfilePage from './pages/ProfilePage';
import { DefaultSEO } from './components/SEO/DefaultSEO';
import Tables from './pages/Tables';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

// Update the existing ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    // Store the attempted URL in localStorage
    localStorage.setItem('redirectAfterLogin', location.pathname);
    // Pass the location in state for immediate access
    return <Navigate to="/auth" state={{ from: location.pathname }} />;
  }

  return children;
};

// Add this component to track page views
const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

// Create a new component for the root route
const RootRoute = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // If user is logged in, redirect to Lobby
    // If not, show CommunitiesPage
    if (user) {
      navigate('/lobby');
    }
  }, [user, navigate]);

  // Show CommunitiesPage by default
  return <CommunitiesPage />;
};

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    initGA();
  }, []);

  return (
    <HelmetProvider>
      <AuthProvider>
        <DefaultSEO />
        <Router>
          <RouteTracker />
          <Routes>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/" element={<RootRoute />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/communities" element={<CommunitiesPage />} />
            <Route 
              path="/communities/new" 
              element={
                <ProtectedRoute>
                  <CreateCommunityPage />
                </ProtectedRoute>
              } 
            />
            <Route path="/communities/:name" element={<CommunityDetailsPage />} />
            <Route 
              path="/lobby" 
              element={
                <ProtectedRoute>
                  <Lobby />
                </ProtectedRoute>
              } 
            />
           
            <Route 
              path="/profile" 
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/table/:gameId" 
              element={
                <ProtectedRoute>
                  <Table />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/join/:gameId" 
              element={
                <ProtectedRoute>
                  <JoinGame />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/host" 
              element={
                <ProtectedRoute>
                  <HostView />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/host/table/:gameId" 
              element={
                <ProtectedRoute>
                  <HostTableView />
                </ProtectedRoute>
              } 
            />
            <Route path="/admintable/:gameId" element={<PokerTable />} />
            <Route 
              path="/tables" 
              element={
                <ProtectedRoute>
                  <Tables />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App; 