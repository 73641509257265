import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Card from '../Card/Card';
import Chip from '../Chip/Chip';
import HandEvaluator from '../../utils/HandEvaluator';
import axios from 'axios';
import config from '../../config';

const ChipIcon = styled.svg`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  vertical-align: middle;
  fill: currentColor;
`;


const getAvatarUrl = (username) => {
  if (!username) return null;
  // Using DiceBear avatars API for consistent avatar generation based on username
  return `https://api.dicebear.com/7.x/avataaars/svg?seed=${username}`;
};

const SeatWrapper = styled.div.attrs(props => ({
  style: {
    width: props.isCurrentPlayer ? '58px' : '48px',
    height: props.isCurrentPlayer ? '58px' : '48px',
    backgroundImage: props.username ? 
      (props.image && props.image !== '' ? 
        `url(${props.image})` : 
        `url(${getAvatarUrl(props.username)})`) : 
      'none',
    backgroundPosition: 'center center',
   // backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    padding: '0px',
    zIndex: 20,
    '--timer-background': props.isActive && (props.gameStatus === 'PLAYING' || props.gameStatus === 'WAITING') ?
      `conic-gradient(
        rgba(0, 0, 0, 0) ${props.timeLeft}%,
        rgba(0, 194, 255, 0.3) ${props.timeLeft}%
      )` : 'none'
  }
}))`
  position: absolute;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease;

  ${({ position, hasPlayer }) => {
    const positions = {
      0: { bottom: '5%', left: 'calc(50% - 43px)', transform: 'translateX(-50%)' },
      1: { bottom: '25%', left: '2%' },
      2: { top: '25%', left: '2%' },
      3: hasPlayer 
         ? { top: '5%', left: 'calc(50% - 43px)', transform: 'translateX(-50%)' }
         : { top: '5%', left: '50%', transform: 'translateX(-50%)' },
      4: { top: '25%', right: '2%' },
      5: { bottom: '25%', right: '2%' }
    };
    return css`
      ${Object.entries(positions[position] || {})
        .map(([key, value]) => `${key}: ${value};`)
        .join(';')}
    `;
  }}

  &::after {
    content: '';
    position: absolute;
    inset: -2px;
    border-radius: 50%;
    background: var(--timer-background);
    transform: rotate(-90deg);
    transition: background 0.1s linear;
  }
`;

const PlayerInfo = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 8px;

  .player-name {
    font-size: ${props => props.isCurrentPlayer ? '16px' : '14px'};
    font-weight: 600;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 4px;
  }

  .stack {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: ${props => props.isCurrentPlayer ? '14px' : '12px'};
    color: #4eff9f;
    font-weight: 500;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }

  .status {
    font-size: ${props => props.isCurrentPlayer ? 
      `clamp(10px, 1.8vw, 13px)` : 
      `clamp(8px, 1.6vw, 11px)`};
  }
`;

const ProfileImage = styled.div`
  position: absolute;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
  width: ${props => props.isCurrentPlayer ? '42px' : '36px'};
  height: ${props => props.isCurrentPlayer ? '42px' : '36px'};
  border-radius: 50%;
  background: ${props => props.image ? 
    `url(${props.image}) center/cover no-repeat` : 
    'transparent'};
  border: 3px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;

  ${props => props.isActive && css`
    border-color: ${props.timeLeft <= 25 ? '#ff4444' : '#00c2ff'};
  `}

  @media (max-width: 768px) {
    left: -24px;
    width: ${props => props.isCurrentPlayer ? '34px' : '30px'};
    height: ${props => props.isCurrentPlayer ? '34px' : '30px'};
  }
`;

const zoomCards = keyframes`
  0% {
    transform: scale(1) rotate(var(--rotation));
  }
  100% {
    transform: scale(1.8) rotate(var(--rotation));
  }
`;

const InfoBox = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  padding: 6px 12px;
  width: 86px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1;

  ${props => {
    if (props.position === 4 || props.position === 5) {
      return css`
        right: calc(100% + 8px);
      `;
    }
    return css`
      left: calc(100% + 8px);
    `;
  }}

  .player-name {
    font-size: ${props => props.isCurrentPlayer ? '14px' : '12px'};
    font-weight: 600;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }

  .stack {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: ${props => props.isCurrentPlayer ? '14px' : '12px'};
    color: #4eff9f;
  }

  .action {
    font-size: 12px;
    color: #00c2ff;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
  }

  .inactive {
    color: #ff4444;
  }

  .sitting-out {
    color: #ff4444;
  }

  .hand-strength {
    font-size: 12px;
    color: #ffd700;
    text-align: center;
    font-weight: 500;
  }
`;

const PlayerCards = styled.div`
  position: absolute;
  top: -35px;
  transform: translateX(-50%) scale(1.05);
  display: flex;
  gap: 2px;
  z-index: 0;
  width: 86px;

  > * {
    transform: translateY(0);
    width: 42px;
    height: 58px;
  }

  ${props => {
    if (props.position === 4 || props.position === 5) {
      return css`
        right: calc(50% + 8px);
      `;
    }
    return css`
      left: calc(100% + 58px);
    `;
  }}

  ${props => props.isCurrentPlayer && css`
    transform: translateX(-50%) scale(1.1);
    
    > * {
      transform: translateY(0);
    }
  `}

  justify-content: center;
`;

const BetContainer = styled.div`
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 4px;
  z-index: 25;
`;

const DealerButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background: #00c2ff;
  color: #000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 2px 6px rgba(0, 194, 255, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.8);
  z-index: 2;
`;

const EmptySeat = styled(SeatWrapper).attrs(props => ({
  style: {
    width: '48px',
    height: '48px',
    backgroundColor: 'transparent',
    padding: '2px',
    zIndex: 10,
    background: 'transparent'  // Override the default avatar background
  },
}))`
  border: 2px dashed rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const PlayerName = styled.div`
  font-weight: bold;
  color: #ffd700;
  font-size: 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
`;

const StatusIndicator = styled.div`
  font-size: ${props => props.isCurrentPlayer ? 
    `clamp(11px, 1.8vw, 14px)` : 
    `clamp(10px, 1.6vw, 12px)`};
  padding: 4px 8px;
  border-radius: 12px;
  margin-top: 6px;
  font-weight: 500;
  
  &.sitting-out {
    color: #ff6b6b;
    background: rgba(255, 107, 107, 0.15);
  }
  
  &.folded {
    color: #ffd700;
    background: rgba(255, 215, 0, 0.15);
  }
  
  &.all-in {
    color: #4eff9f;
    background: rgba(78, 255, 159, 0.15);
  }
`;

const WinningChips = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  color: #4caf50;
  font-weight: bold;
  font-size: ${props => props.isCurrentPlayer ? 
    `clamp(14px, 2.2vw, 18px)` : 
    `clamp(12px, 2vw, 16px)`};
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #4caf50;
  opacity: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  gap: 4px;
  
  animation: ${props => props.show ? `
    fadeInUp 0.5s ease-out forwards,
    fadeOut 0.5s ease-out forwards 2s
  ` : 'none'};

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate(-50%, 20px);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const HandStrength = styled.div`
  font-size: ${props => props.isCurrentPlayer ? 
    `clamp(10px, 1.8vw, 13px)` : 
    `clamp(8px, 1.6vw, 11px)`};
  padding: 2px 6px;
  border-radius: 4px;
  margin-top: 2px;
  color: ${props => {
    switch (props.rank) {
      case 10: // Royal Flush
      case 9:  // Straight Flush
      case 8:  // Four of a Kind
        return '#00ff00'; // Bright green
      case 7:  // Full House
      case 6:  // Flush
      case 5:  // Straight
        return '#ffd700'; // Gold
      case 4:  // Three of a Kind
      case 3:  // Two Pair
        return '#ffa500'; // Bright orange
      default: // Pair or High Card
        return '#ff4444'; // Bright red
    }
  }};
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
`;

const fetchProfileImages = async (playerIds) => {
  console.log('🎯 fetchProfileImages called with IDs:', playerIds);
  try {
    const response = await axios.post(`${config.apiUrl}/api/auth/profile-images`, { playerIds });
    console.log('🌐 API Response:', response.status, response.statusText);
    console.log('📦 Data received:', response.data);
    return response.data.success ? response.data.profileImages : {};
  } catch (error) {
    console.error('❌ Error fetching profile images:', error);
    return {};
  }
};

const PlayerSeat = ({ 
  player, 
  position, 
  isActive, 
  isDealer, 
  isCurrentPlayer,
  seatIndex,
  isShowdown,
  isActivePlayer,
  gameState,
  winningAmount
}) => {
  const [progress, setProgress] = useState(100);
  const [handEvaluation, setHandEvaluation] = useState(null);
  const handEvaluator = new HandEvaluator();
  const [profileImages, setProfileImages] = useState({});

  useEffect(() => {
    console.log('🔄 PlayerSeat useEffect triggered with player:', player);
    if (player?.userId) {
      console.log('👤 Fetching profile for player ID:', player.userId);
      fetchProfileImages([player.userId])
        .then(images => {
          console.log('🖼️ Received images for player:', images);
          setProfileImages(images);
        })
        .catch(error => {
          console.error('❌ Error in useEffect:', error);
        });
    }
  }, [player?.userId]);

  const {
    username,
    chips,
    currentBet,
    cards,
    folded,
    isAllIn,
    isActive: playerIsActive,
    profileImage: propProfileImage,
    lastAction,
    handStrength,
    sittingOut,
  } = player || {};

  const profileImage = profileImages[player?.userId] || propProfileImage;

  useEffect(() => {
    if (!isActive || !gameState?.status || gameState.status === 'WAITING') {
      setProgress(100);
      return;
    }

    if (gameState.status !== 'PLAYING') {
      return;
    }

    const startTime = gameState?.timer?.startTime || Date.now();
    const duration = gameState?.timer?.duration || 30000; // 30 seconds default

    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const remaining = Math.max(0, duration - elapsed);
      const progressPercent = (remaining / duration) * 100;
      setProgress(progressPercent);

      if (remaining === 0) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isActive, gameState?.timer, gameState?.status]);

  useEffect(() => {
    if (cards && cards.length === 2 && playerIsActive && !folded) {
      const evaluation = handEvaluator.evaluatePlayerHand(
        cards,
        gameState?.communityCards || []
      );
      setHandEvaluation(evaluation);
    } else {
      setHandEvaluation(null);
    }
  }, [cards, gameState?.communityCards, playerIsActive, folded]);

  if (!player) {
    return (
      <EmptySeat position={position} hasPlayer={false}>
        <div>Seat {seatIndex + 1}</div>
      </EmptySeat>
    );
  }

  const shouldRevealCards = 
    isCurrentPlayer || 
    (isShowdown && isActivePlayer && !folded);

  const truncateUsername = (username) => {
    if (!username) return 'UNKNOWN';
    return username.length > 8 ? `${username.slice(0, 8).toUpperCase()}...` : username.toUpperCase();
  };

  return (
    <SeatWrapper 
      position={position} 
      isActive={isActive} 
      isCurrentPlayer={isCurrentPlayer}
      timeLeft={progress}
      image={profileImage}
      gameStatus={gameState?.status}
      hasPlayer={!!player}
      username={username}
    >
      {isDealer && <DealerButton>D</DealerButton>}
      <InfoBox 
        isCurrentPlayer={isCurrentPlayer}
        position={position}
      >
        {!isCurrentPlayer && (
          <div className="player-name">
            {truncateUsername(username)}
          </div>
        )}
        <div className="stack">
          <ChipIcon viewBox="0 0 24 24">
            <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Z" />
          </ChipIcon>
          {chips || 0}
        </div>
        {isCurrentPlayer ? (
          <div className="hand-strength">
            {handEvaluation ? handEvaluation.name : 'High Card'}
          </div>
        ) : (
          <div className={`action ${!playerIsActive ? 'inactive' : ''}`}>
            {!playerIsActive ? 'Sitting Out' : lastAction || ''}
          </div>
        )}
      </InfoBox>
      
      {playerIsActive && cards && cards.length > 0 && (
        <PlayerCards isCurrentPlayer={isCurrentPlayer} position={position}>
          {cards.map((card, index) => (
            <Card 
              key={index} 
              rank={card.rank} 
              suit={card.suit}
              size={isCurrentPlayer ? "medium" : "small"}
              faceDown={!shouldRevealCards}
            />
          ))}
        </PlayerCards>
      )}
      
      {currentBet > 0 && (
        <BetContainer>
          <Chip amount={currentBet} />
        </BetContainer>
      )}
      
      {winningAmount > 0 && (
        <WinningChips 
          isCurrentPlayer={isCurrentPlayer}
          show={true}
        >
          <ChipIcon viewBox="0 0 24 24">
            <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Z" />
          </ChipIcon>
          +{winningAmount}
        </WinningChips>
      )}
    </SeatWrapper>
  );
};

export default PlayerSeat; 