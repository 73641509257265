import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [isPWA, setIsPWA] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, register } = useAuth();

  // Check if we came from a game invite
  const redirectPath = location.state?.from || localStorage.getItem('redirectAfterLogin');

  useEffect(() => {
    const checkMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    };
    
    setIsMobile(checkMobile());

    // Only check PWA status for mobile devices
    if (checkMobile()) {
      const isPWACheck = () => {
        return window.matchMedia('(display-mode: standalone)').matches ||
               window.navigator.standalone ||
               document.referrer.includes('android-app://');
      };
      setIsPWA(isPWACheck());
    } else {
      // Always set isPWA to true for desktop
      setIsPWA(true);
    }

    if (checkMobile()) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Remove the PWA requirement check and replace with a recommendation
    if (isMobile && !isPWA) {
      setShowModal(true); // Show PWA instructions
      // But continue with auth
    }

    try {
      if (isLogin) {
        const result = await login(email, password);
        if (result.success) {
          if (redirectPath) {
            localStorage.removeItem('redirectAfterLogin');
            // Check if redirectPath is '/communities' and redirect to '/lobby' instead
            if (redirectPath === '/communities') {
              navigate('/lobby');
            } else {
              navigate(redirectPath);
            }
          } else {
            navigate('/lobby');
          }
        }
      } else {
        if (!username.trim()) {
          setError('Username is required');
          return;
        }
        const result = await register(email, password, username);
        if (result.success) {
          if (redirectPath) {
            localStorage.removeItem('redirectAfterLogin');
            // Check if redirectPath is '/communities' and redirect to '/lobby' instead
            if (redirectPath === '/communities') {
              navigate('/lobby');
            } else {
              navigate(redirectPath);
            }
          } else {
            navigate('/lobby');
          }
        }
      }
    } catch (error) {
      console.error('Auth error:', error);
      setError(error.message || 'Authentication failed');
    }
  };

  const handleInstall = async () => {
    if (deferredPrompt) {
      // If native prompt is available, use it (Android Chrome)
      try {
        await deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          setDeferredPrompt(null);
        }
      } catch (error) {
        console.error('Install prompt error:', error);
        setShowModal(true); // Fallback to instructions
      }
    } else {
      // For iOS or when native prompt isn't available
      setShowModal(true);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
  };

  return (
    <AuthWrapper>
      <AuthForm onSubmit={handleSubmit}>
        <h2>{isLogin ? 'Login' : 'Register'}</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        
        {!isPWA && isMobile && (
          <PWAPrompt>
            <p>Get the best experience by installing our app!</p>
            <InstallButton type="button" onClick={handleInstall}>
              Add to Home Screen
            </InstallButton>
          </PWAPrompt>
        )}

        {showModal && (
          <Modal>
            <ModalContent>
              <ModalHeader>
                <h3>Add to Home Screen</h3>
                <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
              </ModalHeader>
              
              <ModalBody>
                {/iPhone|iPad|iPod/.test(navigator.userAgent) ? (
                  <InstructionsList>
                    <li>1. Tap the share icon <ShareIcon>⎋</ShareIcon> at the bottom of your browser</li>
                    <li>2. Scroll and tap "Add to Home Screen"</li>
                    <li>3. Tap "Add" in the top right</li>
                    <li>4. Return to the app after installation</li>
                  </InstructionsList>
                ) : (
                  <InstructionsList>
                    <li>1. Tap the three dots menu ⋮ at the top of your browser</li>
                    <li>2. Tap "Install app" or "Add to Home Screen"</li>
                    <li>3. Tap "Install" to confirm</li>
                    <li>4. Return to the app after installation</li>
                  </InstructionsList>
                )}
               
              </ModalBody>
            </ModalContent>
            <ModalOverlay onClick={() => setShowModal(false)} />
          </Modal>
        )}

        {redirectPath && (
          <RedirectMessage>
            Please {isLogin ? 'log in' : 'register'} to join the poker game
          </RedirectMessage>
        )}

        <GoogleButton type="button" onClick={handleGoogleLogin}>
          <img 
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=" 
            alt="Google logo" 
          />
          Continue with Google
        </GoogleButton>

        <Divider>
          <span>or use email</span>
        </Divider>

        <EmailFormToggle onClick={() => setShowEmailForm(!showEmailForm)}>
          {showEmailForm ? 'Hide email login' : 'Login via email'}
        </EmailFormToggle>

        {showEmailForm && (
          <>
            {!isLogin && (
              <FormGroup>
                <label>Username</label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Choose a display name"
                  required={!isLogin}
                  minLength={3}
                  maxLength={20}
                  pattern="[A-Za-z0-9_]+"
                  title="Username can only contain letters, numbers, and underscores"
                />
              </FormGroup>
            )}
            
            <FormGroup>
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
              />
            </FormGroup>
            
            <FormGroup>
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength={6}
                placeholder="Enter your password"
              />
            </FormGroup>

            <SubmitButton type="submit">
              {isLogin ? 'Login' : 'Register'}
            </SubmitButton>
            
            <ToggleButton type="button" onClick={() => {
              setIsLogin(!isLogin);
              setError('');
              setUsername('');
            }}>
              {isLogin ? 'Need an account? Register' : 'Have an account? Login'}
            </ToggleButton>
          </>
        )}

        <PrivacyLink to="/privacy-policy">
          Privacy Policy
        </PrivacyLink>
      </AuthForm>
    </AuthWrapper>
  );
};

const AuthWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #1a1a1a;
`;

const AuthForm = styled.form`
  background: #2d2d2d;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  color: white;

  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ccc;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #444;
    border-radius: 4px;
    background: #333;
    color: white;
    font-size: 1rem;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: #4caf50;
    }

    &::placeholder {
      color: #666;
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: #388e3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 1rem;
  transition: background 0.3s ease;

  &:hover {
    background: #2e7d32;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ToggleButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: transparent;
  color: #4caf50;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  margin-bottom: 1rem;
  text-align: center;
`;

const RedirectMessage = styled.div`
  background: rgba(52, 152, 219, 0.1);
  border: 1px solid rgba(52, 152, 219, 0.3);
  color: #3498db;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
`;

const InstallButton = styled(SubmitButton)`
  background: #2196f3;
  margin-top: 1rem;

  &:hover {
    background: #1976d2;
  }
`;

const InstallInstructions = styled.div`
  margin-top: 0.5rem;
  padding: 0.75rem;
  background: rgba(33, 150, 243, 0.1);
  border: 1px solid rgba(33, 150, 243, 0.3);
  border-radius: 4px;
  color: #2196f3;
  text-align: center;
  font-size: 0.9rem;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const ShareIcon = styled.span`
  display: inline-block;
  transform: rotate(90deg);
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #2d2d2d;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  color: white;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalBody = styled.div`
  margin-bottom: 1rem;
`;

const InstructionsList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 1rem;
`;

const InstallImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin-top: 1rem;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const PWAPrompt = styled.div`
  background: rgba(33, 150, 243, 0.1);
  border: 1px solid rgba(33, 150, 243, 0.3);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;

  p {
    margin: 0 0 0.5rem 0;
    color: #2196f3;
  }
`;

const PrivacyLink = styled(Link)`
  display: block;
  text-align: center;
  color: #888;
  font-size: 0.9rem;
  margin-top: 1rem;
  text-decoration: none;
  
  &:hover {
    color: #4caf50;
    text-decoration: underline;
  }
`;

const GoogleButton = styled(SubmitButton)`
  background: white;
  color: #757575;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.75rem;
  position: relative;

  &:hover {
    background: #f5f5f5;
  }

  img {
    width: 18px;
    height: 18px;
  }
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
  color: #888;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #444;
  }

  span {
    padding: 0 10px;
    font-size: 0.9rem;
  }
`;

const EmailFormToggle = styled.button`
  background: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;

  &:hover {
    color: #4caf50;
    text-decoration: underline;
  }
`;

export default Auth; 